import {colors, fonts, textVariants} from '@commercial-helios/ui/theme'
import React from 'react'
import styled, {css} from 'styled-components'

import {useGlobalStrings} from '@/providers/global-strings'
import {fluidType} from '@/utils/fluid'
import type {FontColor} from '@/utils/is-valid-font-color'

export interface TextProps {
    children?: React.ReactNode
    $color?: FontColor
    $center?: boolean
    $upperCase?: boolean
    $variant?: keyof typeof textVariants
    forwardedAs?: keyof JSX.IntrinsicElements | React.ComponentType<any>
}

export interface Props extends React.HTMLProps<HTMLParagraphElement>, TextProps {
    as?: any
}

const upperCaseStyles = css`
    text-transform: uppercase;
`

const centerStyles = css`
    text-align: center;
`

export const getTextVariantStyles = (variant: NonNullable<Props['$variant']>) => css`
    font-family: ${textVariants[variant].fontFamily};
    font-weight: ${textVariants[variant].fontWeight};
    line-height: ${textVariants[variant].lineHeight};

    ${fluidType('font-size', textVariants[variant].fontSize)}
`

type FormatContent = <T>(
    raw: T,
    replaceContent: (arg: string) => string,
) => string | T | React.ReactElement | React.ReactElement[]

const formatContent: FormatContent = (raw, replaceContent) => {
    if (typeof raw === 'string') {
        return replaceContent(raw)
    }

    if (React.isValidElement<any>(raw)) {
        return React.cloneElement(
            raw,
            [],
            raw.props.children && Array.isArray(raw.props.children)
                ? raw.props.children.map((child: React.ReactElement) =>
                      formatContent(child, replaceContent),
                  )
                : formatContent(raw.props.children, replaceContent),
        )
    }

    if (Array.isArray(raw)) {
        return raw.map((el) => formatContent(el, replaceContent))
    }

    return raw
}

export const text = ({$color, $center, $upperCase, $variant = 'paragraph-large'}: Props) => css`
    margin: 0;
    font-family: inherit;
    font-weight: normal;
    line-height: 1.6;
    word-break: break-word;
    color: ${$color ? colors.text[$color] : 'inherit'};
    ${$center && centerStyles}
    ${$upperCase && upperCaseStyles}
    ${$variant && getTextVariantStyles($variant)}
`

const StyledText = React.forwardRef<HTMLParagraphElement, Props>(
    ({as: TextComponent = 'p', forwardedAs, id, children, ...props}, ref) => {
        const globalStrings = useGlobalStrings()
        const primaryFontVariants = Object.keys(textVariants).filter(
            (variant) =>
                textVariants[variant as NonNullable<Props['$variant']>].fontFamily ===
                fonts.families.primary,
        )

        const primaryFont = 'primary'

        const isPrimaryVariant = !props.$variant || primaryFontVariants.includes(props.$variant)

        const replaceContent = (text: string) =>
            text.replace('{{numberOfCustomers}}', globalStrings.num_customers)

        return (
            <TextComponent
                {...props}
                id={id}
                ref={ref}
                as={forwardedAs}
                {...(isPrimaryVariant && {'data-font': primaryFont})}
            >
                {formatContent(children, replaceContent)}
            </TextComponent>
        )
    },
)

export const Text = styled(StyledText)<Props>(text)
