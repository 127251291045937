import {tokens} from '@commercial-helios/ui/theme'
import {asText} from '@prismicio/helpers'
import type {PrismicRichTextProps} from '@prismicio/react'
import {PrismicRichText} from '@prismicio/react'
import React from 'react'
import styled, {css} from 'styled-components'

import Image from '@/components/image'
import PrismicLink from '@/components/prismic-link'
import type {Props as PrismicLinkProps} from '@/components/prismic-link'
import type {TextLinkProps} from '@/components/text'
import {Text, TextLink} from '@/components/text'
import mq from '@/utils/media-query'
import {stringToSlug} from '@/utils/string-to-slug'

export interface Props extends Omit<TextLinkProps, 'content'> {
    content: string | PrismicRichTextProps['field'] | null
    withQuotes?: boolean
    className?: string
    htmlFor?: string
}

const RichText = ({
    $variant,
    $color,
    $center,
    $upperCase,
    content,
    $bgColor,
    withQuotes,
    as,
    forwardedAs,
    className,
    htmlFor,
}: Props) => {
    const textProps = {$variant, $color, $center, $upperCase, as, forwardedAs, className}

    if (typeof content === 'string') {
        return (
            <Text {...textProps} htmlFor={htmlFor}>
                {withQuotes ? `"${content}"` : content}
            </Text>
        )
    } else if (hasRichText(content)) {
        return (
            <Wrapper withQuotes={withQuotes} className={className}>
                <PrismicRichText
                    field={content}
                    components={{
                        image: ({key, node}) => (
                            <Image
                                key={key}
                                image={node}
                                width={node.dimensions.width}
                                height={node.dimensions.height}
                            />
                        ),
                        paragraph: ({key, children}) => (
                            <Text {...textProps} key={key} htmlFor={htmlFor}>
                                {children}
                            </Text>
                        ),
                        hyperlink: ({key, node, children}) => {
                            const linkData = node.data as PrismicLinkProps

                            return (
                                <TextLink
                                    {...linkData}
                                    {...textProps}
                                    $bgColor={$bgColor}
                                    key={key}
                                    forwardedAs={PrismicLink}
                                >
                                    {children}
                                </TextLink>
                            )
                        },
                        heading2: ({key, node, children}) => {
                            const id = stringToSlug(node.text)

                            return (
                                <Text
                                    {...textProps}
                                    $variant="title-base"
                                    as="h2"
                                    id={id}
                                    key={key}
                                >
                                    {children}
                                </Text>
                            )
                        },
                        heading3: ({key, node, children}) => {
                            const id = stringToSlug(node.text)

                            return (
                                <Text
                                    {...textProps}
                                    $variant="paragraph-large-medium"
                                    as="h3"
                                    id={id}
                                    key={key}
                                >
                                    {children}
                                </Text>
                            )
                        },
                        heading4: ({key, node, children}) => {
                            const id = stringToSlug(node.text)

                            return (
                                <Text
                                    {...textProps}
                                    $variant="paragraph-large"
                                    as="h4"
                                    id={id}
                                    key={key}
                                >
                                    {children}
                                </Text>
                            )
                        },
                        heading5: ({key, node, children}) => {
                            const id = stringToSlug(node.text)

                            return (
                                <Text
                                    {...textProps}
                                    $variant="paragraph-base"
                                    as="h5"
                                    id={id}
                                    key={key}
                                >
                                    {children}
                                </Text>
                            )
                        },
                        heading6: ({key, node, children}) => {
                            const id = stringToSlug(node.text)

                            return (
                                <Text
                                    {...textProps}
                                    $variant="paragraph-base"
                                    as="h6"
                                    id={id}
                                    key={key}
                                >
                                    {children}
                                </Text>
                            )
                        },
                        oListItem: ({key, children}) => (
                            <Text {...textProps} as="li" key={key}>
                                <span>{children}</span>
                            </Text>
                        ),
                        listItem: ({key, children}) => (
                            <Text {...textProps} as="li" key={key}>
                                <span>{children}</span>
                            </Text>
                        ),
                        list: ({key, children}) => <UL key={key}>{children}</UL>,
                        oList: ({key, children}) => <OL key={key}>{children}</OL>,
                    }}
                />
            </Wrapper>
        )
    } else {
        return null
    }
}

export const hasRichText = (content: Props['content']) => {
    if (typeof content === 'string') {
        return !!content
    } else {
        return !!content && !!asText(content)
    }
}

const listStyles = css`
    display: grid;
    gap: ${tokens.spacing16};

    ${mq.min('md')} {
        gap: ${tokens.spacing28};
    }
`

const UL = styled.ul`
    ${listStyles}
`

const OL = styled.ol`
    ${listStyles}
`

type WrapperProps = Pick<Props, 'withQuotes'>

const withQuotes = css`
    p:first-child::before {
        content: '"';
    }

    p:last-child::after {
        content: '"';
    }
`

const Wrapper = styled.div<WrapperProps>`
    > * {
        margin: 0;
    }

    && > * + * {
        margin-top: ${tokens.spacing28};

        ${mq.min('md')} {
            margin-top: ${tokens.spacing40};
        }
    }

    && > h2 + *,
    && > h3 + *,
    && > h4 + *,
    && > h5 + *,
    && > h6 + * {
        margin-top: ${tokens.spacing12};
    }

    label {
        display: block;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        a {
            font: inherit;
        }
    }

    ${(props) => props.withQuotes && withQuotes}
`

export default RichText
