import {PrismicPreviewProvider} from 'gatsby-plugin-prismic-previews'
import type {ReactNode} from 'react'
import React from 'react'

import {GoogleReCaptchaProvider} from '@/providers/google-recaptcha'

// Adds a shared React Context for Prismic preview sessions.
type WrapRootElementProps = {
    children: ReactNode
}
export const WrapRootElement = ({children}: WrapRootElementProps) => (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_V3_SITE_KEY!}>
        <PrismicPreviewProvider>{children}</PrismicPreviewProvider>
    </GoogleReCaptchaProvider>
)
