import {tokens, colors} from '@commercial-helios/ui/theme'
import type {CategoryPreferences, Destination} from '@segment/consent-manager/types/types'
import Cookies from 'js-cookie'
import React from 'react'
import styled, {css} from 'styled-components'

import {
    cookieBannerActioned,
    cookiePreferencesActioned,
    CookieBannerActionedAction,
    CookiePreferencesActionedAction,
} from '@/analytics/segment'
import Button from '@/components/button'
import type {Props as RichTextProps} from '@/components/rich-text'
import RichText from '@/components/rich-text'
import {text} from '@/components/text'
import {cookiesMap} from '@/config/cookies'
import type {ImageSource} from '@/types/image'
import {ConsentState, updateConsentsState} from '@/utils/google-ads-consents'
import mq from '@/utils/media-query'

import Modal from './cookie-banner-modal'

const breakpoint = 'sm'

type ConsentManagerBuilderRenderProps = {
    saveConsent: (newPreferences?: boolean | CategoryPreferences, shouldReload?: boolean) => void
    setPreferences: (newPreferences: CategoryPreferences) => void
    newDestinations: Destination[]
}

export type CookieBannerContent = {
    banner_body: RichTextProps['content']
    banner_button_accept: string
    manage_cookies_modal_link: string
    modal_image: ImageSource
    modal_title: string
    tracking_categories: string
    ads_disclaimer: RichTextProps['content']
    necessary_cookies_label: string
    always_on_label: string
    necessary_cookies_disclaimer: RichTextProps['content']
    modal_save_changes_button: string
}

interface Props extends ConsentManagerBuilderRenderProps {
    content: CookieBannerContent
}

export const CookieBanner = ({content, saveConsent, setPreferences, newDestinations}: Props) => {
    const [isBannerVisible, setIsBannerVisible] = React.useState(false)
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const hasNewDestinations = !!newDestinations.length

    React.useEffect(() => {
        if (!Cookies.get(cookiesMap.trackingPreferences) || hasNewDestinations) {
            cookieBannerActioned({action: CookieBannerActionedAction.Shown})
            setIsBannerVisible(true)
        }
    }, [hasNewDestinations])

    const savePreferences = (cookiesAllowed: boolean) => {
        setPreferences({})
        const shouldReload = !cookiesAllowed
        saveConsent(cookiesAllowed, shouldReload)

        setIsBannerVisible(false)
    }

    if (!isBannerVisible) {
        return null
    }

    return (
        <>
            <GridCell>
                <Wrapper offsetFloatingBtn data-testid="cookie-banner-wrapper">
                    <RichText $variant="label-base" content={content.banner_body} />

                    <CookieBannerButtonsWrapper>
                        <AllowCookiesButton
                            onClick={() => {
                                savePreferences(true)
                                cookieBannerActioned({
                                    action: CookieBannerActionedAction.Dismissed,
                                })
                                updateConsentsState(ConsentState.Granted)
                            }}
                            data-testid="accept-cookies-button"
                        >
                            {content.banner_button_accept}
                        </AllowCookiesButton>

                        <ManageCookiesButton
                            onClick={() => {
                                cookieBannerActioned({
                                    action: CookieBannerActionedAction.Shown,
                                })
                                setIsModalOpen(true)
                            }}
                        >
                            {content.manage_cookies_modal_link}
                        </ManageCookiesButton>
                    </CookieBannerButtonsWrapper>
                </Wrapper>
            </GridCell>

            <Modal
                isOpen={isModalOpen}
                onDismiss={() => {
                    cookiePreferencesActioned({
                        action: CookiePreferencesActionedAction.Closed,
                    })
                    setIsModalOpen(false)
                }}
                onSave={(cookiesAllowed) => {
                    cookiePreferencesActioned(
                        {action: CookiePreferencesActionedAction.Saved},
                        undefined,
                        () => {
                            savePreferences(cookiesAllowed)
                        },
                    )
                    setIsModalOpen(false)
                }}
                content={content}
            />
        </>
    )
}

interface WrapperProps {
    offsetFloatingBtn?: boolean
}

const Wrapper = styled.div<WrapperProps>(({offsetFloatingBtn}) => {
    // If Intercom or other button is not shown, we can make the banner full-width on mobile
    const offsetFloatingBtnStyles = css`
        ${mq.max(breakpoint)} {
            width: calc(100% - 70px);
        }
    `

    return css`
        display: grid;
        gap: ${tokens.spacing8};
        align-items: center;
        padding: ${tokens.spacing10};
        background-color: ${colors.background.default};
        border-radius: ${tokens.arc4};
        box-shadow: ${tokens.shadowElevate};

        ${offsetFloatingBtn && offsetFloatingBtnStyles};

        ${mq.min(breakpoint)} {
            max-width: 320px;
        }
    `
})

const GridCell = styled.div`
    padding: ${tokens.spacing24};

    * {
        pointer-events: initial;
    }
`

const ManageCookiesButton = styled.button`
    ${text({$variant: 'label-base'})}

    max-width: max-content;
    padding: 0;
    color: ${tokens.shade600};
    text-decoration: underline;
    cursor: pointer;
    background: none;
    border: none;
`

const AllowCookiesButton = styled(Button)`
    /* the && bumps the specificity of the selector.
    We do it as a workaround styled components changing specificity after build */
    && {
        padding: ${tokens.spacing6} ${tokens.spacing20};
        ${text({$variant: 'label-base', $color: 'inverted'})}
    }
`

const CookieBannerButtonsWrapper = styled.div`
    display: grid;
    gap: ${tokens.spacing8};
    grid-template-columns: auto auto;
    align-items: center;

    ${mq.min('xs')} {
        gap: ${tokens.spacing16};
        grid-template-columns: auto 1fr;
    }
`
