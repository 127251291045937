import {tokens, colors} from '@commercial-helios/ui/theme'
import * as React from 'react'
import styled, {css} from 'styled-components'

import mq from '@/utils/media-query'
import type {BackgroundColor} from '@helios/website/src/utils/is-valid-background-color'

export const gutters = css`
    padding-right: ${tokens.spacing24};
    padding-left: ${tokens.spacing24};

    ${mq.min('md')} {
        padding-right: ${tokens.spacing40};
        padding-left: ${tokens.spacing40};
    }

    ${mq.min('xl')} {
        padding-right: 100px;
        padding-left: 100px;
    }
`

export const container = css`
    width: 100%;
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;

    ${gutters};
`

export const Container = styled.div`
    ${container}
`

interface WidthConstrainedSectionProps {
    $padding?: 'small' | 'regular'
    $edgeToEdge?: boolean
}

const WidthConstrainedSection = styled.section<WidthConstrainedSectionProps>(
    ({$padding = 'regular', $edgeToEdge}) => {
        return css`
            ${!$edgeToEdge && container}

            padding-top: ${tokens.spacing40};
            padding-bottom: ${tokens.spacing40};

            ${mq.min('md')} {
                padding-top: ${$padding === 'small' ? tokens.spacing40 : '80px'};
                padding-bottom: ${$padding === 'small' ? tokens.spacing40 : '80px'};
            }
        `
    },
)

type SectionProps = React.ComponentProps<typeof WidthConstrainedSection> & {
    hideOverflow?: boolean
    padding?: WidthConstrainedSectionProps['$padding']
    edgeToEdge?: WidthConstrainedSectionProps['$edgeToEdge']
}

export const Section = React.forwardRef<HTMLElement, SectionProps>(
    ({hideOverflow, padding, edgeToEdge, ...props}, ref) => (
        <div
            style={{
                overflowX: hideOverflow ? 'hidden' : 'unset',
            }}
        >
            <WidthConstrainedSection
                ref={ref}
                $padding={padding}
                $edgeToEdge={edgeToEdge}
                {...props}
            />
        </div>
    ),
)

export const TitleLayout = styled.div`
    width: 100%;

    ${mq.min('sm')} {
        width: calc((100% - ${tokens.spacing24}) / 2);
    }

    ${mq.min('md')} {
        width: calc((100% - ${tokens.spacing40}) / 1.9 * 0.9);
    }

    ${mq.min('xl')} {
        width: calc((100% - 100px) / 1.9 * 0.9);
    }
`

export interface LayoutProps {
    $variant: 'two-asymmetrical' | 'two-equal' | 'three-equal' | 'four-equal'
    $mirrored?: boolean
    $alignItems?: 'start' | 'center' | 'end' | 'stretch'
}

export const Layout = styled.div<LayoutProps>(({$variant, $mirrored, $alignItems = 'start'}) => {
    const mirroredStyles = css`
        direction: rtl;

        > * {
            direction: initial;
        }
    `

    const twoAsymmetricalStyles = css`
        ${mq.min('sm')} {
            grid-template-columns: repeat(2, 1fr);
        }

        ${mq.min('md')} {
            grid-template-columns: 0.9fr 1fr;
            gap: ${tokens.spacing40};
        }

        ${mq.min('xl')} {
            row-gap: ${tokens.spacing60};
            column-gap: 100px;
        }

        ${$mirrored && mirroredStyles}
    `

    const twoEqualStyles = css`
        ${mq.min('sm')} {
            grid-template-columns: repeat(2, 1fr);
        }

        ${mq.min('md')} {
            gap: ${tokens.spacing40};
        }

        ${mq.min('xl')} {
            row-gap: ${tokens.spacing60};
            column-gap: 100px;
        }
    `

    const threeEqualStyles = css`
        ${mq.min('sm')} {
            grid-template-columns: repeat(3, 1fr);
        }

        ${mq.min('md')} {
            gap: ${tokens.spacing40};
        }

        ${mq.min('xl')} {
            gap: ${tokens.spacing60};
        }
    `

    const fourEqualStyles = css`
        ${mq.min('xs')} {
            grid-template-columns: repeat(2, 1fr);
        }

        ${mq.min('sm')} {
            grid-template-columns: repeat(3, 1fr);
        }

        ${mq.min('md')} {
            gap: ${tokens.spacing40};
            grid-template-columns: repeat(4, 1fr);
        }

        ${mq.min('xl')} {
            gap: ${tokens.spacing20};
        }
    `

    return css`
        display: grid;
        grid-template-columns: 1fr;
        align-items: ${$alignItems};
        gap: ${tokens.spacing24};

        ${$variant === 'two-asymmetrical' && twoAsymmetricalStyles}
        ${$variant === 'two-equal' && twoEqualStyles}
        ${$variant === 'three-equal' && threeEqualStyles}
        ${$variant === 'four-equal' && fourEqualStyles}
    `
})

export interface BackgroundWrapperProps {
    $color?: BackgroundColor
}

export const BackgroundWrapper = styled.div<BackgroundWrapperProps>`
    background: ${(props) => props.$color && colors.background[props.$color]};
`
